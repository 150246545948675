@import "../../styles/colors";

.RecipeButton {
    border-radius: 50%;
    width: fit-content;
    aspect-ratio: 1/1;
    color: $bright-white;
    padding: .25rem;

    svg {
        padding: .1rem;
        font-size: 1rem;
    }

}

.RecipeButton:Hover {
    // background-color: $bright-white;
    outline: none;
    color: $secondary;

}