@import '../../styles/_colors.scss';

.GenerateRecipeDisplay {
    color: $charcoal;

    h2 {
        color: $primary;
    }
}

.GenerateRecipeDisplay-nav {
    width: 100%;
    background-color: $muted-dark;
    padding: 1rem 0;
}

.GenerateRecipeDisplay-button {
    width: 15rem;
    margin: auto;
    color: $bright-white;
    border: $primary solid 1px;
    border-radius: 0;
    background-color: $muted-dark;

    &.selected {
        background-color: $bright-white;
        border: 2px $primary solid;
        color: $primary;
    }
}

.GenerateRecipeDisplay-button:Hover {
    background-color: $bright-white;
    color: $primary;
}

.GenerateRecipeDisplay-contents {
    min-height: 400px;
    padding: 1rem;
    width: 100%;
}

.GenerateRecipeDisplay-formContainer {
    height: 100%;
    width: 100%;
}