@import '../../styles/_colors.scss';

.RecipeList-empty {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $charcoal;
    margin:0;

    & svg {
        margin:4rem 0 2rem 0;
        color: $almond;
        font-size: 6rem;
    }
}