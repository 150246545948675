@import '../../styles/_recipe-mixins.scss';

.IngredientList {
    @include IngredientList;
}

.Ingredient-empty {
    //icon for empty ingredient area
    font-size:6rem;
    color: $muted;
    opacity:50%;
    margin-left: 2rem;
}