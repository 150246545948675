@import '../../styles/_recipe-mixins.scss';

.IngredientInput {
    @include Ingredient
}


.IngredientInput-delete {
    //positioning
    position:absolute;
    line-height: 0px;
    font-size: .75rem;
    right: 0rem;
    top:0rem;

    //appearance
    color: $butternut;
    background-color: white;
    border: 1px solid white;
    border-radius:50%;
}