@import "../../styles/colors";
@import "../../styles/_variables";


.NavBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: .5rem 1rem .5rem 1rem;

    background-color: $bright-white;
    width: 100%;

    color: $charcoal;

    height:var(--nav-height);
}

.NavBar-logo>svg {
    height: 2.5rem;
    max-width: 2.5rem;
    aspect-ratio: 1;
}

.NavBar-home {
    a {
        font-weight: 700;
        font-size: large;
        text-decoration: none;
        color: $charcoal;
        display: flex;
        align-items: center;

        svg {
            margin-right: .5rem;
            transform: translateY(-.15rem);
            color: $charcoal;
        }
    }
}

.NavBar-search {

    margin: 1rem;
    height: 1.5rem;
    background-color: $muted-light;

    border-radius: 1rem;
    box-shadow: none;

    border: solid 1px charcoal;

    .MuiFormControl-root {
        height: 100%;
    }

    .MuiInputBase-root {
        height: 100%;

        .MuiInputBase-input {
            margin: 0;
        }

        & .MuiOutlinedInput-root {
            border: none;
        }
    }

    & svg {
        color: $charcoal;
    }

    & * {
        border-radius: 1rem;
        border: none;
        outline: none;
        box-shadow: none;
    }

    input {
        color: $charcoal;
    }

}


.NavBar-links {
    font-size: larger;
    color: $charcoal;

    & a,
    button {
        display: flex;
        font-size: 1rem;
        color: $charcoal;
        font-weight: 400;
        list-style-type: none;
        border-radius: 1rem;
        padding: 1rem;

        span {
            text-decoration: none;
        }

        & svg {
            color: $charcoal;
        }
    }
}

.NavBar-dropdown {

    .NavBar-menuItem {
        text-decoration: none;
        color: $charcoal;
        width:100%;
    }

    .NavBar-dropdownItems {
        color: $charcoal;
        width:100%;
        padding: .5rem 1rem;
    }


    & ul {
        background-color: $bright-white;
    }

}

.loginModal {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}