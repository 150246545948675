// @import '../../styles/_recipe-mixins.scss';
@import "../../styles/colors";

.RecipeForm{
    margin: 1rem;
    margin-top: 200px;
    background-color: #fcf7f0;
    z-index:5;
    position:relative;
    border-radius: 5px;
}

.RecipeInfo-editImage {
    color:$charcoal;
}

.RecipeBanner {
    position:absolute;
    top:0px;
    left:0;
    width:100%;
}

.Recipe-submitButton {
    position:sticky;
    bottom:0;
    left:0;
    padding:1rem;

    font-size: 1.25rem;
    z-index: 1500;
    display:flex;
    flex-direction: row;
    justify-content: right;
}

// .Recipe-errorMessage {
//     padding:1rem;
// }