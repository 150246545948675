@import "../styles/colors";

/************************** RECIPE ******************/

@mixin Recipe {
    margin: 1rem;
    margin-top: 200px;
}



/************************** STEPS ******************/

@mixin StepList {
    @content
}

@mixin Step {
    width: 100%;
    position: relative;
    padding:1rem;

    .Step-vdivider {
        background-color: $primary;
        width:3px;
    }
    .Step-hdivider {
        background-color: $primary;
        height:2px;
    }


}

@mixin StepInput {
    padding:1rem;
    border: solid 1px #afafaf;
    border-radius:5px;
    margin: 2rem 0;
    position:relative;

    .StepInput-delete {
        margin:.5rem .5rem 1rem .5rem;
        position:absolute;
        top:0;
        right:0;
        font-size: 1.5rem;
        color:$primary;
        border:none;
    }
    .StepInput-delete:hover, .StepInput-delete:focus {
        cursor: pointer;
        color:$salmon;
    }
}


@mixin Step-number {
        align-self: center;
        font-size: 4rem;
        font-weight: 900;
        font-style: italic;
        color:$primary;
        margin-right: 1rem;
        margin-bottom: 1rem;
}

@mixin Step-ingredients {
        width:100%;

        @media (min-width:900px){
            width:300px;
        }
    }

Step-ingredientsForm {
    width:100%
}

@mixin Step-instructions {
        width:100%;
}


//Instruction rules
@mixin Instruction {
    resize: none;
    width:100%;
    height: 100%;
    min-height: fit-content;
    font-weight:300;
}

/************************** INGREDIENTS ******************/

// Ingredient-list rules
@mixin IngredientList {
    list-style: none;
    flex-wrap: wrap;
    align-items: center;
    width:100%;

    //handle the form elements slightly differently for this section
    .IngredientList-item {
        width:100%;
        display:inline-block;
        position:relative;
        padding-left:0px;
        padding-right: 0px;

        @media (min-width:900px){
                width: 50%;
                flex-shrink: 0;
            }
        }

        > :nth-child(even){
            @media (min-width:900px){
                padding-left:.5rem;
            }

    }

}

// Ingredient rules
@mixin Ingredient {
    width:100%;
    font-weight:900;

    .IngredientInput-delete {
        color:$secondary;
        font-size: 1rem;

        position:absolute;
        top:0px;
        right:-6px;
    }

    .IngredientInput-delete:hover, .IngredientInput-delete:focus {
        cursor: pointer;
        color:$primary;
    }

    .MuiFormGroup-root {
        width:100%;
    }

    div {
        display:inline-block;
    }

    .Ingredient-text,
    .Ingredient-text textarea,
    .Ingredient-text input {
        font-weight: 900;
        text-transform: uppercase;
        width:100%;
        overflow:hidden;
    }

    .Ingredient-text textarea {
        display:inline-block;
    }

    .MuiFormLabel-root {
        font-size: .5rem;
    }

}
