@import '../../styles/_recipe-mixins.scss';

.RecipeCard {
    margin: 1rem;
    margin-top: 300px;
    z-index: 5;
    border-radius: 5px;

    @media (min-width:600px) {
        margin-top: 200px;
    }
}

.RecipeCard-RecipeInfo {
    background-color: $primary;
    border-radius: 5px;
    color: $bright-white;
    height: 100%;
    padding: 1rem;
    flex: 1 1 50%;
    position: relative;

    @media (min-width:600px) {
        padding: 2rem;
    }

    .RecipeInfo-sourceName a,
    .RecipeInfo-owner a {
        color: $bright-white;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 2px;
    }

    .RecipeInfo-name {
        color: $secondary;
        text-decoration: none;
    }

    .FavoriteButton {
        position: absolute;
        top: 0rem;
        right: 0rem;
        font-size: 1rem;
        align-self: flex-start;
        flex-basis: 2rem;
        flex-grow: 0;
        flex-shrink: 0;
        min-width: 2rem;

        @media (min-width:600px) {
            top: 0rem;
            right: 0rem;
            font-size: 1.5rem;
        }
    }
}

.RecipeCard-actions {
    background-color: $muted-light;
    color: $charcoal;
    margin:0;
}
.RecipeCard-actionsIconContainer .RecipeButton {
    color: $charcoal;
}

.RecipeBanner {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 350px;
    object-fit: cover;
}

