@import '../styles/_colors.scss';

.RegistrationPage {
    min-height: calc(80vh);
    height: 100%;
    overflow: hidden;

    img {
        object-fit: cover;
        position: absolute;
        right: 0;
        top: 0;
        width:100%
    }

    .RegistrationPage-form {
        z-index: 10;
        width: 80%;
    }
}