.ImageForm {
    align-items: center;

    .ImageForm-uploadButton{
        width:150px;
    }

    ImageForm-fileName {
        width:200px;
    }

    ImageForm-uploadButton{
        margin:auto;
    }
}