@import '../styles/_colors.scss';

.WelcomePage {
    text-align:center;
    margin:4rem 0;

    & a{
        padding:1rem;
    }

    & h2 {
        margin-bottom:2rem;
        color:$charcoal;
        padding:1rem;
    }

    & svg {
        font-size: 4rem;
    }
}