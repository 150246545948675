@import '../styles/_recipe-mixins.scss';
@import "../styles/colors";
@import "../styles/_variables";


section {
    height: 100vh;
    width: 100%;
    position: relative;
}

.headlinePanel-left,
.headlinePanel-right {
    z-index: 10;
    color: $bright-white;
    height: 50%;
    width: 100%;
    padding: 5%;
    flex: 1 0 40%;

    p,
    h1 {
        width: 80%;
        position: relative;
        color: $charcoal
    }

    @media (min-width:600px) {
        height: 100%;
        width: 40%;

        p,
        h1 {
            width: 100%;
        }
    }
}

.headlinePanel-left {
    border-radius: 0 0 100px 0;

    @media (min-width:600px) {
        border-radius: 0 0 200px 0;
    }
}

.headlinePanel-right {
    border-radius: 0 0 0 100px;

    @media (min-width:600px) {
        border-radius: 0 0 0 200px;
    }
}

.imagePanel {
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 0 1 60%;

    >div {
        margin: 1rem;
    }

    img {
        width: 100vw;
        height: 100%;
        object-fit: cover;
        position: absolute;
        right: 0;
        top: 0;

        @media (min-width:600px) {
            height: 100%;

        }
    }
}

/** HERO SECTION STYLING **/

.Hero {
    height: calc(100vh - var(--nav-height)); //compensate for navbar
    width: 100%;
    background-position: center;
    background-size:cover;
    position:relative;
    animation: floatyBackground 20s ease-in-out infinite;

    @keyframes floatyBackground {
        0% {
            background-size: 100%;
        }
        50% {
            background-size: 110%;
        }
        100% {
            background-size: 100%;
        }
    }
    // from css in js
    // background-image: url('/images/hero-banner.jpg');

    .Headline-container {
        width:100%;
        height:100%;
        padding: 1rem 2rem;
        background-color: mix($muted-light, #00000000, 90%);
        z-index: 1;
        // box-shadow: 0 0 15px mix($charcoal, #00000000, 50%);
        text-align: center;
        padding-bottom:0;
        h1 {
            padding-bottom:0;
            max-width: 80%;
            color:$charcoal;
        }
        .subtitle {
            color:$charcoal;
        }
    }

    .Hero-more {
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        padding: .5rem;
        margin: auto;
        color: $bright-white;
        background-color: $info;
        z-index:2;
    }

    .Hero-next {
        color: $info;
        animation: bounce 2s infinite linear;
    }

    @keyframes bounce {
        20% {
            transform:translateY(0%) scale(100%);
            animation-timing-function: ease-in-out;
        }
        30% {
            transform:translateY(-20%) scale(101%, 98%);
            animation-timing-function: ease-in-out;
        }
        40% {
            animation-timing-function: ease-out;
            transform:translateY(0%) scale(98%, 102%);
        }
        50% {
            transform:translateY(-10%) scale(102%, 98%);
            animation-timing-function: ease-out;
        }
        60% {
            transform:translateY(-0%) scale(98%, 102%);
            animation-timing-function: ease-out;
        }
        70% {
            transform:translateY(-5%) scale(102%, 98%);
            animation-timing-function: ease-out;
        }
        80% {
            transform:translateY(0%) scale(100%);
            animation-timing-function: ease-out;
        }
    }
}


/** FORMAT SECTION STYLING **/
#format {
    min-height: calc(120vh); //compensate for navbar
    width: 100%;
    background-size:contain;
}

.Format-headlinePanel {
    background-color: $secondary;
}

.Format-contents {


    padding:3rem;
    @media (min-width:600px) {
        padding: 2rem;
    }

    img {
        width: 100%;
        height: calc(100vh - 3rem);
        object-fit: cover;
        position: absolute;
        right: 0;
    }
}

.Homepage-animation {
    background-color: $bright-white;
    z-index: 10;
    max-height: 90%;
    padding: 1rem;
    border-radius: 1rem;

    > svg {
        aspect-ratio: 1;
    }
}



//Register Section styles
.CTA {
    position: static;
    padding: 2rem;
    background-color: $bright-white;
}

.CTA-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

.CTA-headlinePanel {
    background-color: $primary;

    p,
    h1 {
        color: $bright-white;
    }

    @media (min-width:600px) {
        width: 100%;
    }

}

.CTA-imagePanel {

    color:$charcoal;
    img {
        width: 100%;
        object-fit: cover;
        position: absolute;
        right: 0;
    }

    .CTA-addRecipeLink {
        background-color: $bright-white;
        padding: 3rem;
        z-index: 10;
        border-radius: 1rem;

        svg {
            color: $secondary;
            font-size: 6rem;
        }
    }
}

.CTA-form {
    z-index: 10;
    width:70%;
}




// FEATURES SECTION

.Features {
    min-height: 100vh;
    color: $info;
    padding: 10%;
    text-align: center;
    text-wrap: pretty;
}

.Features-grid {
    width: 100%;
    padding: 3rem 0%;
    background-color: $muted-light;

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    gap: 1rem;

    height: fit-content;
    width:fit-content;
    align-items: start;

    * {
        margin: 0 auto;
        text-wrap: pretty;
    }

    p {
        max-width: 50%;
        margin-bottom:2rem;
        color:$charcoal;
    }

    @media (min-width:600px) {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 3rem 0%;
        align-items: stretch;
        grid-auto-flow: column;


        p {
            max-width: 100%;
        }
    }
}

.Features-feature {
    margin: 0 auto;
    text-align: center;
    color: $charcoal;
    width:100%;
    grid-column: span 1;
    align-items: start;

    img {
        margin:auto;
        max-height: 150px;
    }

    h3 {
        margin-top:1rem;
        color:$info;
    }

    p {
        margin:1.5rem auto;
        align-self:start;
    }

}

.Features-featureIcon {
    aspect-ratio: 1;

    @media (min-width:600px) {
        margin-bottom: 1rem;
        max-width: 65%;
    }
}