@import "../../styles/colors";

.RecipeFromText {

    width:100%;
    
    .MuiFormControl-root {
        width:100%;
        background-color: $bright-white;
        width:100%;
        margin-bottom:1rem;
    }


    .RecipeFromText-title {
        color:$primary;
    }
}