@import '../../styles/_recipe-mixins.scss';


.StepInput {
    @include StepInput;
}

.StepInput-error {
    @include StepInput;
    border: solid 3px $primary;
}

.Step-number {
    @include Step-number;
}

.Step-ingredients {
    @include Step-ingredients;
}

.Step-instructions {
    @include Step-instructions;
}


