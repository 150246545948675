@import '../../styles/_colors.scss';

.UserRecipesNav {
    width: 100%;
    background-color: $muted-dark;
    padding: 1rem 0;

    div {
        width: 40%;
    }

    .selected {
        background-color: $bright-white;
        border: 2px $primary solid;
        color: $primary;
    }
}

.UserRecipesNav-button {
    width: 100%;
    margin: auto;
    color: $bright-white;
    border: $primary solid 1px;
    border-radius: 0;
}

.UserRecipesNav-button:Hover {
    background-color: $bright-white;
    color: $primary;
}
