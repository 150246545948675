@import '../../styles/_colors.scss';

.BugReportPanel{
    background-color: $secondary;
    border-radius: 50%;
    width:fit-content;
    position: fixed;
    bottom:1rem;
    right:0;
    transform:translate(-50%,0);

    z-index: 10;

    & svg {
        font-size: 1rem;
    }

}